<template>
  <section class="modal--container auth--container otp--container">
    <SectionLoader :show="isLoading" />
    <div class="title--text">
      {{ $t('general.otpTitle') }}
      <span>{{ $t('general.forVerifyPhone') }}</span>
    </div>
    <div class="desc">
      {{ $t('general.otpSentTo') }}
      <span v-if="user && user.phone_number">{{ user.phone_number }}</span>
    </div>
    <div class="auth--link mb-4" v-if="from !== 'profile'" @click="changePhoneNumber">
      {{ $t('general.changePhoneNumber') }}
    </div>
    <!--    <div class="timer">-->
    <!--      05:00-->
    <!--    </div>-->
    <div class="otp--input">
      <!--      <input type="number" class="mr-4" v-model="otp1" :class="isFilled(otp1) ? 'filled' : ''" />-->
      <!--      <input type="number" class="mr-4" v-model="otp2" :class="isFilled(otp2) ? 'filled' : ''" />-->
      <!--      <input type="number" class="mr-4" v-model="otp3" :class="isFilled(otp3) ? 'filled' : ''" />-->
      <!--      <input type="number" v-model="otp4" :class="isFilled(otp4) ? 'filled' : ''" />-->
      <v-otp-input length="6" v-model="otp" type="number" @finish="verifyOtp"></v-otp-input>
      <span class="val-error" v-if="validation.hasError('otp')">{{
        validation.firstError('otp')
      }}</span>
    </div>
    <div class="auth--link" :class="{ disabled: !allowResend }" @click="resendOtp">
      {{ $t('general.resendOtp') }}
    </div>
    <div v-if="allowOtpRequestAt">
      {{ $t('loginRegister.waitOtp') }}:<vue-countdown
        class="countdown mb-2"
        :time="new Date(allowOtpRequestAt) - new Date()"
        :interval="1000"
        :transform="transformSlotProps"
        @start="countdownStart"
        @end="countdownEnd"
        v-slot="{ minutes, seconds }"
        >{{ minutes }}:{{ seconds }}</vue-countdown
      >
    </div>
    <div class="btn btn-primary main--btn" @click="verifyOtp">
      {{ $t('general.verifyOtp') }}
    </div>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
const SectionLoader = () => import('@/components/content-loading/section-loading');
import VueCountdown from '@chenfengyuan/vue-countdown';
import { mapState } from 'vuex';

export default {
  props: ['from'],
  components: {
    SectionLoader,
    VueCountdown,
  },
  data: () => ({
    otp: null,
    isLoading: false,
    allowOtpRequestAt: null,
    allowResend: true,
  }),
  computed: {
    ...mapState({
      user: (state) => state.global.user,
    }),
  },
  validators: {
    otp(value) {
      return Validator.value(value).required(this.$i18n.t('errors.otp.required'));
    },
  },
  methods: {
    // isFilled(value) {
    //   return value !== null && value !== '';
    // },

    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },

    countdownEnd() {
      this.allowOtpRequestAt = null;
      this.allowResend = true;
    },
    countdownStart() {
      this.allowResend = false;
    },
    changePhoneNumber() {
      this.$router.replace('/input-phone');
    },
    async resendOtp() {
      if (this.allowResend) {
        try {
          this.isLoading = true;
          const { data } = await this.$store.dispatch('global/updatePhoneSendOtp', {
            phone: this.user.phone_number,
          });
          this.allowOtpRequestAt = data.allow_next_request_at;
        } catch (e) {
          const { status } = e.response || {};
          if (status === 465) {
            this.allowOtpRequestAt = e.response.data.allow_next_request_at;
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    async verifyOtp() {
      const isValid = await this.$validate();
      if (isValid) {
        if (this.from !== 'profile') {
          try {
            this.isLoading = true;
            const { data } = await this.$store.dispatch('global/verifyOtp', {
              otp: this.otp,
            });
            console.log('VERIFY OTP: ', data);
            if (data) {
              await this.$store.dispatch('global/fetch');
              this.$router.replace('/');
            }
          } catch (e) {
            console.log('ERROR VERIFY OTP: ', e);
          } finally {
            this.isLoading = false;
          }
        } else {
          this.$emit('verifyOtp', this.otp);
        }
      }
    },
  },
  mounted() {},
};
</script>
